exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mantenimiento-lavadora-jsx": () => import("./../../../src/pages/mantenimiento/lavadora.jsx" /* webpackChunkName: "component---src-pages-mantenimiento-lavadora-jsx" */),
  "component---src-pages-mantenimiento-secadora-jsx": () => import("./../../../src/pages/mantenimiento/secadora.jsx" /* webpackChunkName: "component---src-pages-mantenimiento-secadora-jsx" */),
  "component---src-pages-reparacion-aire-acondicionado-jsx": () => import("./../../../src/pages/reparacion/aire-acondicionado.jsx" /* webpackChunkName: "component---src-pages-reparacion-aire-acondicionado-jsx" */),
  "component---src-pages-reparacion-electrodomesticos-jsx": () => import("./../../../src/pages/reparacion/electrodomesticos.jsx" /* webpackChunkName: "component---src-pages-reparacion-electrodomesticos-jsx" */),
  "component---src-pages-reparacion-estufa-de-gas-jsx": () => import("./../../../src/pages/reparacion/estufa-de-gas.jsx" /* webpackChunkName: "component---src-pages-reparacion-estufa-de-gas-jsx" */),
  "component---src-pages-reparacion-estufa-electrica-jsx": () => import("./../../../src/pages/reparacion/estufa-electrica.jsx" /* webpackChunkName: "component---src-pages-reparacion-estufa-electrica-jsx" */),
  "component---src-pages-reparacion-lavadora-acros-jsx": () => import("./../../../src/pages/reparacion/lavadora/acros.jsx" /* webpackChunkName: "component---src-pages-reparacion-lavadora-acros-jsx" */),
  "component---src-pages-reparacion-lavadora-frigidaire-jsx": () => import("./../../../src/pages/reparacion/lavadora/frigidaire.jsx" /* webpackChunkName: "component---src-pages-reparacion-lavadora-frigidaire-jsx" */),
  "component---src-pages-reparacion-lavadora-jsx": () => import("./../../../src/pages/reparacion/lavadora.jsx" /* webpackChunkName: "component---src-pages-reparacion-lavadora-jsx" */),
  "component---src-pages-reparacion-lavadora-lg-jsx": () => import("./../../../src/pages/reparacion/lavadora/lg.jsx" /* webpackChunkName: "component---src-pages-reparacion-lavadora-lg-jsx" */),
  "component---src-pages-reparacion-lavadora-mabe-jsx": () => import("./../../../src/pages/reparacion/lavadora/mabe.jsx" /* webpackChunkName: "component---src-pages-reparacion-lavadora-mabe-jsx" */),
  "component---src-pages-reparacion-lavadora-samsung-jsx": () => import("./../../../src/pages/reparacion/lavadora/samsung.jsx" /* webpackChunkName: "component---src-pages-reparacion-lavadora-samsung-jsx" */),
  "component---src-pages-reparacion-lavadora-whirlpool-jsx": () => import("./../../../src/pages/reparacion/lavadora/whirlpool.jsx" /* webpackChunkName: "component---src-pages-reparacion-lavadora-whirlpool-jsx" */),
  "component---src-pages-reparacion-microondas-jsx": () => import("./../../../src/pages/reparacion/microondas.jsx" /* webpackChunkName: "component---src-pages-reparacion-microondas-jsx" */),
  "component---src-pages-reparacion-refrigeradora-frigidaire-jsx": () => import("./../../../src/pages/reparacion/refrigeradora/frigidaire.jsx" /* webpackChunkName: "component---src-pages-reparacion-refrigeradora-frigidaire-jsx" */),
  "component---src-pages-reparacion-refrigeradora-jsx": () => import("./../../../src/pages/reparacion/refrigeradora.jsx" /* webpackChunkName: "component---src-pages-reparacion-refrigeradora-jsx" */),
  "component---src-pages-reparacion-refrigeradora-samsung-jsx": () => import("./../../../src/pages/reparacion/refrigeradora/samsung.jsx" /* webpackChunkName: "component---src-pages-reparacion-refrigeradora-samsung-jsx" */),
  "component---src-pages-reparacion-refrigeradora-whirlpool-jsx": () => import("./../../../src/pages/reparacion/refrigeradora/whirlpool.jsx" /* webpackChunkName: "component---src-pages-reparacion-refrigeradora-whirlpool-jsx" */),
  "component---src-pages-reparacion-secadora-jsx": () => import("./../../../src/pages/reparacion/secadora.jsx" /* webpackChunkName: "component---src-pages-reparacion-secadora-jsx" */),
  "component---src-pages-reparacion-televisor-jsx": () => import("./../../../src/pages/reparacion/televisor.jsx" /* webpackChunkName: "component---src-pages-reparacion-televisor-jsx" */)
}

