module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FixZone - Reparación y mantenimiento de electrodomésticos y aire acondicionado en Guatemala.","short_name":"FixZone","icon":"content/assets/favicon-1.png","lang":"es","description":"Reparación y mantenimiento de electrodomésticos y aire acondicionado en Guatemala. Contamos con técnicos certificados en reparaciones.","siteUrl":"https://thefixzone.com","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f7a5fe2c7714ce08b4a52d4cca624926"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NW2CPQL","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Poppins:400,600,700"]}},
    }]
